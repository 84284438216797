import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import Modal from "react-bootstrap/Modal";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { Zoom } from "react-reveal";

function TestimonialsMobile(props) {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    return (
        <Zoom left>
            <div className="testimonials">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Body>
                        <div className="modal-body custom-modal">
                            <div className="image-container">
                                <img className="principal-image" src="/testimonials/principal.png" alt="principal  sfs" />
                            </div>
                            <br /> <br />
                            <h5 className="bold size-12">“Fill your paper with the breathings of your heart.” – William Wordsworth</h5>
                            <br />
                            <p>
                                Journaling can be very helpful for students for not only being more successful in class, but also have more confidence to take on life’s challenges. It boosts
                                confidence, inspires creativity, enhances critical thinking and improves physical and mental health.{" "}
                            </p>
                            <br />
                            <p>
                                To create a positive mindset among the students community, <span className="bold">SFS PUBLIC SCHOOL ICSE & ISC</span> has introduced{" "}
                                <span>Cradle to Classroom & A Jot a Day</span> by Better Life Projects for all the grades. We had a panel of faculties who thoroughly discussed and presented their
                                views on these books. With the myriad benefits of journaling, including improved sleep, decreased anxiety and better academic performance, keeping a journal seems like
                                something all the students should strive to do.
                            </p>
                            <br />
                            <p>
                                <span className="bold">A Jot a Day</span> journal invites you to record your thoughts and progress on specific problems or interest areas. It allows you to create
                                and maintain a dialogue with yourself about your goals - what they are, how you’ll reach them and your progress and challenges along the way. Articulating and tracking
                                your goals in writing makes them real, increasing your motivation and personal accountability.
                            </p>
                            <br />
                            <p>
                                If a twenty minute activity can improve your mental health or strengthen your writing skills or can be life changing, <span className="bold">why not go for it.</span>
                            </p>
                            <br />
                            <p>
                                I would like to congratulate <span className="bold">Ms. Sapna Prajapati</span> for her marvellous creation, expertly researched and well documented. I would like convey
                                my good wishes to her and would like to see more of her work in future.
                            </p>{" "}
                            <br />
                            <p className="bold">Rev. Dr. Jabamalai msfs</p>
                            <p className="bold">Principal</p>
                            <p className="bold">SFS PUBLIC SCHOOL ICSE & ISC</p>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Body>
                        <div className="modal-body custom-modal">
                            <div className="image-container">
                                <img className="principal-image" src="/testimonials/lawrence.png" alt="principal sfs" />
                            </div>
                            <br /> <br />
                            <h5 className="bold size-12">“Give me the child for the first seven years and I'll give you the man.” – Aristotle</h5>
                            <br />
                            <p>
                                <span className="bold">SFS Academy</span> has always believed in true holistic growth of its students; and when this opportunity was presented, we realised the
                                importance of such initiatives and the long-term positive consequences of it on our students. We as a school have always appreciated changes to rudimentary systems and
                                have welcomed them. If such initiatives are not promoted voluntarily by schools, the overall wellbeing of a student could be compromised and that is not the true
                                purpose of education. Since, the introduction of <span className="bold">A Jot a Day and Cradle</span> to Classroom, we have understood the value and the appreciation
                                from parents, teachers, and students equally for presenting such concepts within education.
                            </p>
                            <br />
                            <p>
                                Journaling is a habit cultivated and promoted by some of the greats like Mark Twain, Anne Frank, Beethoven, Charles Darwin, etc., and we copiously want to utilise this
                                opportunity to ensure through journaling children are encouraged to regulate their thought processes and in turn enjoy its benefit through self-awareness, mindfulness,
                                and gratitude.
                            </p>
                            <br />
                            <p>
                                We strive to provide the best of education that is not only limited to academic content but also to support our students beyond the walls of the school. We, appreciate
                                The Better Life Projects for their creative approach in introducing a system that is completely focused on the overall student wellbeing.{" "}
                            </p>
                            <br />
                            <p>
                                They have ensured students at our school are supported appropriately to reap the benefits of this new yet indispensable project. We will continue to collaborate with
                                this organisation and wish them all the very best in their transformative aspirations.
                            </p>
                            <br />
                            <p>
                                SFS Academy wholeheartedly supports and endorses <span className="bold">The Better Life Projects</span>
                            </p>
                            <br />
                            <p className="bold">Rev. Fr. P Maria Lawrence</p>
                            <p className="bold">Principal</p>
                            <p className="bold">SFS PUBLIC SCHOOL ICSE & ISC</p>
                        </div>
                    </Modal.Body>
                </Modal>

                <h2 className="testimonials-heading">Testimonials</h2>
                <div className="testimonials-container">
                    <Swiper
                        modules={[Autoplay, Pagination]}
                        pagination={{
                            clickable: true
                        }}
                        loop
                        autoplay={{ delay: 5500 }}
                        speed={1000}
                        spaceBetween={0}
                        slidesPerView={1}
                    >
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "My kids (9-year-old twins) have just received their journals and are very excited about it. It was a gift from their aunt. We have also started our 5-minute
                                    gratitude walk every night after dinner. Thanks for introducing journal writing to my children and the lovely motivation to keep it going."
                                </p>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/icons/user.png" />
                                    <div className="customer-name">
                                        <h3>Usha Mani Munshi</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "Journaling can be very helpful for students for not only being more successful in class, but also have more confidence to take on life’s challenges. It boosts
                                    confidence, inspires creativity, enhances critical thinking and improves physical and mental health."
                                </p>
                                <span onClick={handleShow} className="read-more">
                                    Read more...
                                </span>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/principal.png" />
                                    <div className="customer-name">
                                        <h3>Rev. Dr. Jabamalai msfs</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "SFS Academy has always believed in true holistic growth of its students; and when this opportunity was presented, we realised the importance of such initiatives
                                    and the long-term positive consequences of it on our students. "
                                </p>
                                <span onClick={handleShow2} className="read-more">
                                    Read more...
                                </span>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/lawrence.png" />
                                    <div className="customer-name">
                                        <h3>Rev. Fr. P Maria Lawrence</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "I have not seen a creative concept as such on the market. The idea of giving back to the society and positive memory compilation is beyond exemplary. The fact that
                                    this organisation has a story behind it and it's fight against stigma on mental health makes it all the more relatable to someone like me who likes to document
                                    almost everything in my life as a form of therpy."
                                </p>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/vishnu.png" />
                                    <div className="customer-name">
                                        <h3>Vishnu</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "Possibly the best gift I could give my niece who is about to sit her boards. This has helped her in exploring her own expectations as a student. Given the crucial
                                    year that it is for these youngsters, a journal like this is paramount and I would highly recommend anyone who has a teenager at home to introduce them to this
                                    concept."
                                </p>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/shruthi.png" />
                                    <div className="customer-name">
                                        <h3>Shruthi</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>
                                    "An absolutely brilliant product that targets to understand grassroot issues in children. It is surprising that schools are not including such initiatives in their
                                    curriculum even in 2021."
                                </p>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/varun.png" />
                                    <div className="customer-name">
                                        <h3>Varun Shetty</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="reviews">
                                <p>" Thank you for bringing the concept of journaling. This has helped me through the highs and lows of life especially during the pandemic "</p>
                                <div className="customer-info">
                                    <img alt="user" className="user-image" src="/testimonials/akash.png" />
                                    <div className="customer-name">
                                        <h3>Akash</h3>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </Zoom>
    );
}

export default TestimonialsMobile;
