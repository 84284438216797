import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "bootstrap/dist/css/bootstrap.css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

// import required modules
import { Autoplay } from "swiper";

function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}

function GallerySlider(props) {
    const [height, width] = useWindowSize();
    return (
        <div>
            <Swiper modules={[Autoplay]} loop autoplay={{ delay: 1000 }} speed={1000} slidesPerView={width > 768 ? 3 : 1} spaceBetween={width > 768 ? 30 : 0} className="mySwiper">
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo1.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo2.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo3.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo4.jpeg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo5.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo6.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo7.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo8.jpg" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="gallery-image-container">
                        <img alt="seminar" style={{ width: "100%" }} src="/workshop/photo9.jpg" />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default GallerySlider;
