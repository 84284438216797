import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import Web from "./components/Home/Web";
import { useState, useEffect } from "react";
import Mobile from "./components/Home/Mobile";

function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}
function Home() {
    const [height, width] = useWindowSize();
    return <>{width > 768 ? <Web /> : <Mobile />}</>;
}

export default Home;
