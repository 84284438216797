import React, { useState, useEffect } from "react";
import ContactForm from "../ContactForm";
import GallerySlider from "./GallerySlider";
import { Zoom } from "react-reveal";

function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}
function Gallery(props) {
    const [height, width] = useWindowSize();
    return (
        <div style={{ width: "100%" }}>
            <div className="gallery-container">
                <Zoom left>
                    <h3 className="gallery-heading">World Mental Health Day 2022</h3>
                    <h4 className="gallery-subheading">Cathedral High School</h4>
                </Zoom>
                <div className="gallery-description">
                    <p>
                        In Honour of World Mental Health Day, 2022, The Better Life Projects organised 'BACK TO BASICS' - A Space for students to creatively express themselves and be recognised for
                        it.
                    </p>
                </div>

                <div style={{ marginTop: "2rem", marginBottom: "3rem" }}>
                    <GallerySlider />
                </div>
                <div className="feedback d-flex align-items-center justify-content-center">
                    <ContactForm mobile={width > 768 ? false : true} />
                </div>
                {width > 768 ? (
                    <div className=" footer d-flex flex-row justify-content-around align-items-center">
                        <h3>SEMINARS & WORKSHOPS</h3>
                        <div>
                            <h3 style={{ fontSize: 30 }}>ADDRESS</h3>
                            <p style={{ color: "#595959", fontSize: 22 }}>The Better Life Projects JP Nagar, Bangalore - 560078</p>
                            <p style={{ color: "#595959", fontSize: 22 }}>Mobile: +91 7975311483</p>
                            <p style={{ color: "#595959", fontSize: 22 }}>Email : info@thebetterlifeprojects.com</p>
                        </div>
                        <div>
                            <img src="/icons/logo.png" alt="logo" style={{ width: "300px" }} />
                            <div style={{ marginTop: 60 }}>
                                <img src="/icons/instagram.png" alt="instragram logo" style={{ width: "50px", margin: "0px 20px" }} />
                                <img src="/icons/facebook.png" alt="facebook logo" style={{ width: "50px", margin: "0px 20px" }} />
                                <img src="/icons/twitter.png" alt="twitter logo" style={{ width: "50px", margin: "0px 20px" }} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="footer d-flex flex-row justify-content-around align-items-center">
                        <div>
                            <h3 className="footer-address-heading">ADDRESS</h3>
                            <p className="footer-address">The Better Life Projects JP Nagar, Bangalore - 560078</p>
                            <p className="footer-address">Mobile: +91 7975311483</p>
                            <p className="footer-address">Email : info@thebetterlifeprojects.com</p>
                        </div>
                        <div>
                            <img src="/icons/logo.png" alt="logo" className="contact-logo" />
                            <div style={{ marginTop: 60, display: "flex" }}>
                                <img src="/icons/instagram.png" alt="instragram logo" className="socials-logo" />
                                <img src="/icons/facebook.png" alt="facebook logo" className="socials-logo" />
                                <img src="/icons/twitter.png" alt="twitter logo" className="socials-logo" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Gallery;
