import React, { useEffect, useState } from "react";

function UserProfile(props) {
    const [user, setUser] = useState({ name: props.user, email: "" });

    useEffect(() => {
        console.log("Component mounted");
    }, []);

    return (
        <div>
            <h2>{user.name}</h2>
            <p>{user.email}</p>
        </div>
    );
}

export default UserProfile;
