import Lottie from "lottie-react";
import animationData from "../../lottie/waves.json";
import counterAnimation from "../../lottie/counter.json";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import ContactForm from "../ContactForm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { useEffect } from "react";
import ProductSlider from "../ProductSlider";
import { useHistory } from "react-router-dom";
import Testimonials from "../Testimonials";
import { Zoom, Flip } from "react-reveal";
import Tada from "react-reveal/Tada";
import Fade from "react-reveal/Fade";
import LazyLoad from "react-lazyload";

function Web() {
    const [stopped, setStopped] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [startCounter, setStartCounter] = useState(false);
    let navigate = useHistory();

    const routeChange = () => {
        navigate.push("/seminars");
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setStartCounter(true);
            } else {
                setStartCounter(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const changeNavbar = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
            setStartCounter(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", changeNavbar);
    }, []);

    return (
        <>
            <div className="home" id="home">
                <div className="lottie-bg">
                    {!stopped ? <Lottie animationData={animationData} loop={false} autoPlay height={"100vh"} onComplete={() => setStopped(true)} width={"100vw"} /> : null}
                    {stopped ? (
                        <div className="position-relative p-3 p-md-5 overflow-hidden" style={{ zIndex: 100 }}>
                            <img src="/icons/main-logo.gif" alt="logo" className="main-logo" />
                            <Flip left>
                                <h3 className="main-heading">A Symbol of Solidarity Against Stigma on Mental Health</h3>
                            </Flip>
                        </div>
                    ) : null}
                </div>
            </div>

            {startCounter ? (
                <div className="count-container">
                    <Lottie style={{ width: 500, height: 500, marginTop: -180 }} autoplay={true} animationData={counterAnimation} loop={false} />

                    <h2 className="counter-text">of our users are students holding a deliberate and thoughtful conversation with themselves in a completely unaltered voice through our journals.</h2>
                </div>
            ) : null}

            <Zoom left>
                <div className="health-day">
                    <h2 className="home-heading" style={{ color: "#ffe705" }}>
                        WORLD MENTAL HEALTH DAY 2022
                    </h2>

                    <Row className="align-items-center" style={{ padding: "0 80px", margin: "50px 0px 0px 0px" }}>
                        <Col md={8}>
                            <p className="health-day-subheading">Our Proud Winners</p>
                            <LazyLoad>
                                <Swiper pagination modules={[Autoplay, EffectCards]} effect={"cards"} loop autoplay={{ delay: 1000 }} speed={1000} spaceBetween={0} slidesPerView={1}>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo1.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo2.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo3.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo4.jpeg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo5.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo6.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo7.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo8.jpg" />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="photo-card">
                                            <img alt="user" className="health-day-image" src="/workshop/photo9.jpg" />
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </LazyLoad>
                        </Col>
                        <Col style={{ zIndex: 3 }} md={4}>
                            <p style={{ color: "white", fontSize: 25, fontFamily: "Poppins-SemiBold" }}>
                                In Honour of World Mental Health Day, 2022, The Better Life Projects organised 'BACK TO BASICS' - A Space for students to creatively express themselves and be
                                recognised for it.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Zoom>
            <Fade left>
                <div className="story">
                    <h2 className="story-heading">Our Story</h2>

                    <p className="story-content">
                        Our story began when we realised everybody likes talking about the importance of mental health and the need to create awareness, but without any direction or consequence.
                        Mental health is more than just creating awareness. Awareness should motivate action by creating opportunities for people to benefit from. Our story began when we realised
                        there was a disparity in understanding that health consists of both physical and mental well-being. Our goal is not just to propagate but deliver viable means to practice sound
                        mental health. We are reintroducing the lost art of journaling to every individual. To explore their BIG feelings in a safe and non-judgemental environment. Our journals are
                        created with the belief that positive mental health is everyone’s birth right, and it no longer is optional or can be ignored due to long-held stigma against it.
                    </p>
                </div>
            </Fade>
            <Fade right>
                <div className="about" id="about">
                    <h2 className="about-heading">About the author</h2>

                    <Row style={{ margin: "40px 0px" }}>
                        <Col md={5}>
                            <img alt="user" className="author-image" src="/about/author.png" />

                            <Col className="designation-container">
                                <h6 className="designation">Founder: The Better Life Projects</h6>
                                <h6 className="designation">Member of British Psychological Society</h6>
                                <h6 className="designation">Post Graduate Criminology and Criminal Justice</h6>
                                <div className="d-flex align-items-center justify-content-start">
                                    <img alt="ted ex logo" className="ted-ex" src="/about/ted.png" />
                                    <h6 className="designation">Speaker</h6>
                                </div>
                            </Col>

                            {/* <img alt="user" style={{ width: 500 }} src="/about/author.png" /> */}
                        </Col>
                        <Col md={7} style={{ textAlign: "start" }}>
                            <p style={{ color: "white", fontSize: 22 }}>
                                I am not going to bore you by talking about myself in third person. I have always enjoyed direct and personal conversations, and this is going to be no different.
                            </p>
                            <br />
                            <p style={{ color: "white", fontSize: 22 }}>
                                I am Sapna Prajapati, the founder of The Better Life Projects. I create content that encourages positive self-talk, prioritisation of emotions, and coming to terms with
                                fears and overcoming them through journaling.
                            </p>
                            <br />
                            <p style={{ color: "white", fontSize: 22 }}>
                                Apart from this fancy stuff, I hold a Post Graduate degree in Criminology & Criminal Justice, from the University of Edinburgh and hold a Psychology Membership from the
                                British Psychological Society. In a nutshell, I am an aspiring psychological researcher and a wanderlust.
                            </p>
                            <br />
                            <p style={{ color: "white", fontSize: 22 }}>
                                Now after reading these imposing things about me if you are wondering, what my story is? I want to prioritise mental health by creating a space free of judgements. My
                                hope is that the importance of good and positive mental health is no longer taken for granted or worse concealed just because some people disapprove of it without
                                understanding its true meaning.
                            </p>
                            <br />
                            <p style={{ color: "white", fontSize: 22, fontWeight: 600 }}>Mental Health is not a propaganda. IT IS A PRIORITY.</p>
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Fade left>
                <div className="creation" id="product">
                    <h2 className="home-heading" style={{ color: "#2E1E4D" }}>
                        Our Creations
                    </h2>

                    <div style={{ marginTop: 20 }}>
                        <ProductSlider mobile={false} />
                    </div>
                </div>
            </Fade>

            <Fade right>
                <div className="give-back">
                    <h2 className="home-heading" style={{ webkitTextStroke: "3px #FFF500", color: "black" }}>
                        WE GIVE BACK
                    </h2>

                    <Row className="d-flex px-5" style={{ margin: "30px 0 0 0" }}>
                        <Col md={6} style={{ textAlign: "start" }}>
                            <p style={{ color: "white", fontSize: 23 }}>
                                When the idea of creating journals was conceived, what was even more amazing was that we had stumbled upon an opportunity to give back to the society.
                            </p>
                            <p style={{ color: "white", fontSize: 23 }}>
                                We at The Better Life Projects are working with organisations that help children, adults, and seniors who are differently abled; are HIV or cancer inflicted; and Trusts
                                that home orphans. We want to be a part of their journey by being able to provide for their food, shelter, clothing, education, and medical expenditures. Every single
                                time a journal is bought, a part of the proceeds goes to an NGO. When you decide to purchase a journal from us you are not just helping yourself but also these
                                individuals who are in dire need of basic facilities. In the very near future, we aspire to donate to animal shelters given the fact that animal abuse and neglect is on
                                the rise, and that they deserve a better life.
                            </p>
                            <p style={{ color: "white", fontSize: 23 }}>The details of the NGO that you would be contributing to is mentioned inside the journal you choose to purchase.</p>
                            <p style={{ color: "white", fontSize: 23 }}>Thank you for spinning the wheel of giving.</p>
                        </Col>
                        <Col md={6}>
                            <img alt="user" style={{ width: 500 }} src="/images/we-give.png" />
                        </Col>
                    </Row>
                </div>
            </Fade>

            <Testimonials />
            <Fade left>
                <div className="clientele">
                    <h2>Our Clientele</h2>
                    <div className="clientele-container">
                        <img alt="clientele" className="clientele-image" src="/clientele/clientele-1.png" />
                        <img alt="clientele" className="clientele-image" src="/clientele/clientele-2.png" />
                        <img alt="clientele" className="clientele-image" src="/clientele/clientele-3.png" />
                        <img alt="clientele" className="clientele-image" src="/clientele/clientele-4.png" />
                        <img alt="clientele" className="clientele-image" src="/clientele/clientele-6.png" />
                    </div>
                </div>
            </Fade>
            <div id="contact" className="feedback d-flex align-items-center justify-content-center">
                <ContactForm mobile={false} />
            </div>
            <div style={{ backgroundColor: "#202020", width: "100%", paddingBottom: "3rem" }}>
                <Tada>
                    <h2 style={{ color: "white", marginTop: "3rem", width: "100%", margin: 0, fontSize: "3rem" }} className="dont-let-text">
                        Don't let your story end...
                    </h2>
                </Tada>
            </div>

            <div className=" footer d-flex flex-row justify-content-around align-items-center">
                <button onClick={routeChange} className="btn">
                    <h3>SEMINARS & WORKSHOPS</h3>
                </button>
                <div>
                    <h3 style={{ fontSize: 30 }}>ADDRESS</h3>
                    <p style={{ color: "#595959", fontSize: 22 }}>The Better Life Projects JP Nagar, Bangalore - 560078</p>
                    <p style={{ color: "#595959", fontSize: 22 }}>Mobile: +91 7975311483</p>
                    <p style={{ color: "#595959", fontSize: 22 }}>Email : info@thebetterlifeprojects.com</p>
                </div>
                <div>
                    <img src="/icons/logo.png" alt="logo" style={{ width: "300px" }} />
                    <div style={{ marginTop: 60 }}>
                        <img src="/icons/instagram.png" alt="instragram logo" style={{ width: "50px", margin: "0px 20px" }} />
                        <img src="/icons/facebook.png" alt="facebook logo" style={{ width: "50px", margin: "0px 20px" }} />
                        <img src="/icons/twitter.png" alt="twitter logo" style={{ width: "50px", margin: "0px 20px" }} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Web;
