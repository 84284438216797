import React from "react";
import { Zoom } from "react-reveal";

function Seminars(props) {
    return (
        <div style={{ width: "100%" }}>
            <div className="seminar-container">
                <Zoom left>
                    <h3 className="seminar-heading">SEMINAR & WORKSHOP</h3>
                </Zoom>
                <div className="seminar-description">
                    <p>
                        We work with individuals and organizations to amplify their mental-health communication, opening up about ambitions and goals, career choices, breaking down stereotypes brick
                        by brick, connection, and confidence so they can make an influential impact on the world one day.
                    </p>
                </div>
                <div className="seminar-image-container">
                    <img alt="seminar" style={{ width: "100%" }} src="/workshop/seminar.png" />
                </div>
                <h2 className="review-heading">THEY SAY:</h2>
                <div className="seminar-reviews">
                    <p className="seminar-review">
                        “The session was incredible. The Better Life Projects taught us to find more effective way of interaction rather than blaming individuals.” <br />
                        <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Ma’am, your initiative behind this is wonderful. It is good to know that there are people who really want to change the lives of students. Thank you Ma’am.” <br />
                        <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Being an educator, taking care of students’ mental health is our prime responsibility. In today’s session we were well educated about it. Highly motivated by your words. I
                        pledge to take care of children’s mental health.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Very effective session. Thank you.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “It was very interesting and informative seminar.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “The session was incredible. The Better Life Projects taught us to find more effective way of interaction rather than blaming individuals.” <br />
                        <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Ma’am, your initiative behind this is wonderful. It is good to know that there are people who really want to change the lives of students. Thank you Ma’am.” <br />
                        <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Being an educator, taking care of students’ mental health is our prime responsibility. In today’s session we were well educated about it. Highly motivated by your words. I
                        pledge to take care of children’s mental health.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Very effective session. Thank you.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “It was very interesting and informative seminar.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Mental health topic was constructive. Application based and every effective.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “It was a very interesting and informative session. The session was very useful for us to inculcate in our classes.” <br />
                        <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “I personally don’t feel prepared to help students. What we need from The Better Life Projects is more motivational classes. Help us to figure out what are the problems
                        students facing and helping them to sort out themselves. Help us improve the mental health of students. Help us to help them with facing depression or stress. Thank you.”
                        <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “Suggestions for the next session: In your opinion don’t you think teachers should be screened and authorities should make sure the teachers are of sound mental health? Growing
                        up in sometimes illogical orthodox families, individuals pick toxic qualities such as bias, narcissism, caste hierarchy, unconscious religious and regional bias, gender
                        stereotypes, etc.” <br /> <span className="school-name"> - SFS Public School (ICSE & ISC)</span>
                    </p>
                    <p className="seminar-review">
                        “These journals will surely help children improve their emotional quotient which is the need of the hour.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “It was a nice session. If we include this in our curriculum, it will help us to understand our students better.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “You are a very confident and inspirational speakers.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “You were amazing Ma’am. A very important thing to identify in a child is their ability to grasp grassroot level lessons, and you have made me realise the value of observing
                        small things in children which could have a life-long impact on them. With this initiative we can bring out the best in them. This is the need of the hour.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “All the best. We look forward to welcome you and your initiative. This initiative will definitely help children and motivate them to have a Better Life.”
                        <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The session for teachers was too good and absolutely inspiring.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The session was very helpful for me as a teacher. We hope to start observing students so that we can help them and understand them better. Thank you.”
                        <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “This workshop was very useful and helpful to me and my class students. This was very constructive.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “I feel this is a very good and important initiative that our school has agreed to do. Children should really know who they are through which they can gain confidence and it’s
                        a very good learning experience for teachers as well.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “Very informative session and useful for this generation students especially after a long gap of offline classes.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “I feel good. You explained everything very nicely. We will try and implement your ideas and suggestions to help our students.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The interaction was very helpful as the information provided will be very useful where in we can help children to overcome their problems related to mental health & stress.
                        <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The session was good and provided with important information for lower grade students.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The session was very crisp and to the point. It would add value to include instances of uncommon cases and signs to watch out for.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “I think this was a great session for teachers to take a step ahead to know the young ones and make them open up which is important.” <br />
                        <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “The journals would absolutely benefit adolescents and teenagers.”
                        <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “A great initiative for the young readers where the students’ mental health is well-balanced.” <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                    <p className="seminar-review">
                        “I feel it is so important that emotional health is also seen as important and integral in general and in schools. I wish I had someone help me out emotionally in school and
                        normalise it instead of having to deal with it later/now. So, I really appreciate your efforts to de-stigmatise mental health at schools. Is there a way for teachers to seek
                        help if needed? <br /> <span className="school-name"> - Greenfield High School</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Seminars;
